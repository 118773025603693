<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
	<div>
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg'); height: 200px"></div>
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" :src="userInfo.avatar" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ userInfo.nickname }}</h4>
							<div>
								<p>欢迎来到您的作品集!</p>
							</div>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'tab_' + item.id">{{ item.title }}</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
		</a-card>

		<div v-if="tabsId === 1">
			<div>
				<div class="atlas_box">
					<div class="list_box">
						<div class="list">
							<waterfall :col="4" :gutterWidth="20" :data="atlasData">
								<template>
									<div class="item" v-for="(item, index) in atlasData" :key="'atlas_img_' + item.id" @click="lookImage(item.image, item.width, item.height, item.desc)">
										<div class="img">
											<img :src="item.image" alt="" />
											<div class="coverage">
												<div class="c-line-2">{{ item.desc }}</div>
												<div class="time">{{ item.createtime }}</div>
											</div>
										</div>
										<div class="infos" v-if="item.image">
											<div class="right">
												<div class="icons">
													<a-icon type="heart" />
													<span>{{ item.votes }}</span>
												</div>
												<div class="icons">
													<a-icon type="like" />
													<span>{{ item.likes }}</span>
												</div>
												<div class="icons">
													<a-icon type="eye" />
													<span>{{ item.views }}</span>
												</div>
											</div>
										</div>
									</div>
								</template>
							</waterfall>
						</div>
					</div>
				</div>
			</div>
		</div>
		<CardPreviewImage :show="previewImageShow" :data="previewImageData" @close="previewImageShow = false"></CardPreviewImage>
	</div>
</template>

<script>
	import { Debounce } from "@/utils/tools"
	import CardPreviewImage from "@/components/Cards/CardPreviewImage.vue"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: { CardPreviewImage },
		data() {
			return {
				tabsId: 1,
				tabsList: [{ id: 1, title: "我的绘画" }],
				iconTheme: "outlined", // outlined / filled
				atlasData: [],
				previewImageShow: false,
				previewImageData: {
					desc: "",
					image: "",
					width: "",
					height: ""
				},
				page: 1,
				pagesize: 20
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"])
		},
		watch: {},
		created() {
			this.getDrawingRecord()
			window.addEventListener("scroll", this.handleScroll, false)
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll, false)
		},
		methods: {
			...mapActions("user", ["getUserInfo"]),
			handleScroll: Debounce(
				function () {
					let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
					let clientHeight = document.documentElement.clientHeight
					let scrollHeight = document.documentElement.scrollHeight
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						this.page++
						this.getDrawingRecord()
					}
				},
				20,
				false
			),
			// 查看图片
			lookImage(img, w, h, desc) {
				this.previewImageData = {
					desc,
					image: img,
					width: w,
					height: h
				}
				this.previewImageShow = true
			},
			// 获取绘画记录
			getDrawingRecord() {
				this.$http("drawing.record", { page: this.page, pagesize: this.pagesize }).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							this.atlasData = this.atlasData.concat(res.data)
						} else {
							this.page--
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.atlas_box {
		width: 100%;
		margin-bottom: 20px;

		.list_box {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.list {
				::v-deep .vue-waterfall-column {
					width: calc((100% - 60px) / 4);
				}

				.item {
					margin-bottom: 20px;

					.img {
						position: relative;
						border-radius: 8px;
						overflow: hidden;
						cursor: pointer;

						&:hover {
							.coverage {
								display: flex;
							}
						}

						img {
							width: 100%;
							height: auto;
							border-radius: 8px;
						}

						.coverage {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: rgba($color: #000000, $alpha: 0.5);
							display: none;
							flex-direction: column;
							justify-content: flex-end;
							padding: 10px 10px;
							font-size: 12px;
							color: #fff;

							.time {
								color: #a5adb7;
								margin-top: 8px;
							}
						}
					}

					.infos {
						margin-top: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #838fa2;
						font-size: 12px;

						.left {
							display: flex;
							align-items: center;
							.avatar {
								width: 22px;
								height: 22px;
								border-radius: 50%;
								overflow: hidden;
								border: 1px solid #fff;
								margin-right: 8px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							.nickname {
								max-width: 100px;
							}
						}

						.right {
							display: flex;
							align-items: center;

							.icons {
								display: flex;
								align-items: center;
								margin-left: 8px;

								span {
									margin-left: 2px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
